"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INDEX_PAGE = exports.FLOW_AUTH_COOKIE_INITIALIZED = exports.FLOW_BACK_BUTTON_CLICKED = exports.GET_FLOW_EXPORTED_NAMESPACE_ERROR = exports.GET_FLOW_EXPORTED_NAMESPACE_SUCCESS = exports.GET_FLOW_EXPORTED_NAMESPACE_START = exports.FLOW_FUNCTION_ERROR = exports.FLOW_FUNCTION_SUCCESS = exports.FLOW_FUNCTION_START = exports.FLOW_CONTINUE_ACTIONS = exports.FLOW_CONTINUE_ERROR = exports.FLOW_CONTINUE_SUCCESS = exports.FLOW_CONTINUE_START = exports.FLOW_SUBMIT_ACTIONS = exports.FLOW_SUBMIT_VALIDATION_ERROR = exports.FLOW_SUBMIT_ERROR = exports.FLOW_SUBMIT_SUCCESS = exports.FLOW_SUBMIT_START = exports.FLOW_SHARABLE_TOKEN_START_ACTIONS = exports.FLOW_SHARABLE_TOKEN_START_ERROR = exports.FLOW_SHARABLE_TOKEN_START_SUCCESS = exports.FLOW_SHARABLE_TOKEN_START_START = exports.FLOW_START_ACTIONS = exports.FLOW_START_ERROR = exports.FLOW_START_SUCCESS = exports.FLOW_START_START = exports.FLOW_BACK_ACTIONS = exports.FLOW_BACK_ERROR = exports.FLOW_BACK_SUCCESS = exports.FLOW_BACK_START = exports.FLOW_RESOLVE_PAGE_SUCCESS = exports.FLOW_RESOLVE_PAGE_ERROR = exports.FLOW_RESOLVE_PAGE_START = exports.FLOW_CHANGE_PAGE_ERROR = exports.FLOW_CHANGE_PAGE_SUCCESS = exports.FLOW_CHANGE_PAGE_START = void 0;
exports.FLOW_CHANGE_PAGE_START = 'FLOW_CHANGE_PAGE_START';
exports.FLOW_CHANGE_PAGE_SUCCESS = 'FLOW_CHANGE_PAGE_SUCCESS';
exports.FLOW_CHANGE_PAGE_ERROR = 'FLOW_CHANGE_PAGE_ERROR';
exports.FLOW_RESOLVE_PAGE_START = 'FLOW_RESOLVE_PAGE_START';
exports.FLOW_RESOLVE_PAGE_ERROR = 'FLOW_RESOLVE_PAGE_ERROR';
exports.FLOW_RESOLVE_PAGE_SUCCESS = 'FLOW_RESOLVE_PAGE_SUCCESS';
exports.FLOW_BACK_START = 'FLOW_BACK_START';
exports.FLOW_BACK_SUCCESS = 'FLOW_BACK_SUCCESS';
exports.FLOW_BACK_ERROR = 'FLOW_BACK_ERROR';
exports.FLOW_BACK_ACTIONS = {
    start: exports.FLOW_BACK_START,
    error: exports.FLOW_BACK_ERROR,
    success: exports.FLOW_BACK_SUCCESS,
};
exports.FLOW_START_START = 'FLOW_START_START';
exports.FLOW_START_SUCCESS = 'FLOW_START_SUCCESS';
exports.FLOW_START_ERROR = 'FLOW_START_ERROR';
exports.FLOW_START_ACTIONS = {
    start: exports.FLOW_START_START,
    error: exports.FLOW_START_ERROR,
    success: exports.FLOW_START_SUCCESS,
};
exports.FLOW_SHARABLE_TOKEN_START_START = 'FLOW_SHARABLE_TOKEN_START_START';
exports.FLOW_SHARABLE_TOKEN_START_SUCCESS = 'FLOW_SHARABLE_TOKEN_START_SUCCESS';
exports.FLOW_SHARABLE_TOKEN_START_ERROR = 'FLOW_SHARABLE_TOKEN_START_ERROR';
exports.FLOW_SHARABLE_TOKEN_START_ACTIONS = {
    start: exports.FLOW_SHARABLE_TOKEN_START_START,
    error: exports.FLOW_SHARABLE_TOKEN_START_ERROR,
    success: exports.FLOW_SHARABLE_TOKEN_START_SUCCESS,
};
exports.FLOW_SUBMIT_START = 'FLOW_SUBMIT_START';
exports.FLOW_SUBMIT_SUCCESS = 'FLOW_SUBMIT_SUCCESS';
exports.FLOW_SUBMIT_ERROR = 'FLOW_SUBMIT_ERROR';
exports.FLOW_SUBMIT_VALIDATION_ERROR = 'FLOW_SUBMIT_VALIDATION_ERROR';
exports.FLOW_SUBMIT_ACTIONS = {
    start: exports.FLOW_SUBMIT_START,
    error: exports.FLOW_SUBMIT_ERROR,
    success: exports.FLOW_SUBMIT_SUCCESS,
    validationError: exports.FLOW_SUBMIT_VALIDATION_ERROR,
};
exports.FLOW_CONTINUE_START = 'FLOW_CONTINUE_START';
exports.FLOW_CONTINUE_SUCCESS = 'FLOW_CONTINUE_SUCCESS';
exports.FLOW_CONTINUE_ERROR = 'FLOW_CONTINUE_ERROR';
exports.FLOW_CONTINUE_ACTIONS = {
    start: exports.FLOW_CONTINUE_START,
    error: exports.FLOW_CONTINUE_ERROR,
    success: exports.FLOW_CONTINUE_SUCCESS,
};
exports.FLOW_FUNCTION_START = 'FLOW_FUNCTION_START';
exports.FLOW_FUNCTION_SUCCESS = 'FLOW_FUNCTION_SUCCESS';
exports.FLOW_FUNCTION_ERROR = 'FLOW_FUNCTION_ERROR';
exports.GET_FLOW_EXPORTED_NAMESPACE_START = 'GET_FLOW_EXPORTED_NAMESPACE_START';
exports.GET_FLOW_EXPORTED_NAMESPACE_SUCCESS = 'GET_FLOW_EXPORTED_NAMESPACE_SUCCESS';
exports.GET_FLOW_EXPORTED_NAMESPACE_ERROR = 'GET_FLOW_EXPORTED_NAMESPACE_ERROR';
exports.FLOW_BACK_BUTTON_CLICKED = 'FLOW_BACK_BUTTON_CLICKED';
exports.FLOW_AUTH_COOKIE_INITIALIZED = 'FLOW_AUTH_COOKIE_INITIALIZED';
exports.INDEX_PAGE = 'index';
