"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRequest = exports.postRequest = exports.processInitResponse = exports.expectStatus = void 0;
const hub_client_translations_1 = require("@zenoo/hub-client-translations");
const utils_1 = require("@zenoo/hub-client-utils/utils");
const util_1 = require("util");
const Errors_1 = require("../../utils/Errors");
function expectStatus(data, expectedStatus = utils_1.HTTP_STATUS.OK) {
    if ((Array.isArray(expectedStatus) ? expectedStatus : [expectedStatus]).indexOf(data.status) === -1) {
        throw new Errors_1.UnexpectedStatusError(`${hub_client_translations_1.translate('API_REQUEST_FAILED', {
            expectedStatus: expectedStatus.toString(),
            dataStatus: data.status,
        })}/n${hub_client_translations_1.translate(('API_REQUEST_FAILED' + data.status))}`, data);
    }
}
exports.expectStatus = expectStatus;
function processInitResponse(response) {
    if (!util_1.isString(response.data.token)) {
        throw new utils_1.CoreError('API_CAN_NOT_PARSE_TOKEN');
    }
    if (!util_1.isString(response.data.uuid)) {
        throw new utils_1.CoreError('API_CAN_NOT_PARSE_EXEC');
    }
}
exports.processInitResponse = processInitResponse;
function postRequest(url, authToken, body) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield utils_1.Request.post(url, authToken, body);
        expectStatus(response, [utils_1.HTTP_STATUS.CREATED]);
        return response;
    });
}
exports.postRequest = postRequest;
function getRequest(url, authToken, rawData) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield utils_1.Request.get(url, authToken, false, rawData);
        expectStatus(response);
        return response;
    });
}
exports.getRequest = getRequest;
