"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendFile = exports.getFile = exports.exportedNamespace = exports.execute = exports.back = exports.submit = exports.result = exports.status = exports.sharableInit = exports.init = void 0;
const utils_1 = require("@zenoo/hub-client-utils/utils");
const Endpoints_1 = require("../v0/Endpoints");
const ENDPOINTS_URL = __importStar(require("./constants"));
const utils_2 = require("./utils");
function init(serverUrl, flowName, componentFlowId, payload) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = utils_1.buildUrl(ENDPOINTS_URL.INIT, {
            SERVER_URL: serverUrl,
        });
        const response = yield utils_2.postRequest(url, null, Object.assign({ name: flowName, payload }, (componentFlowId ? { id: componentFlowId } : {})));
        utils_2.processInitResponse(response);
        return response.data;
    });
}
exports.init = init;
function sharableInit(serverUrl, token, payload) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = utils_1.buildUrl(ENDPOINTS_URL.SHARABLE_INIT, {
            SERVER_URL: serverUrl,
            TOKEN: token,
        });
        const response = yield utils_2.postRequest(url, null, payload);
        utils_2.processInitResponse(response);
        return response.data;
    });
}
exports.sharableInit = sharableInit;
function status(serverUrl, executionUuid, authToken) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = utils_1.buildUrl(ENDPOINTS_URL.STATUS, {
            SERVER_URL: serverUrl,
            EXECUTION_UUID: executionUuid,
        });
        const response = yield utils_2.getRequest(url, authToken);
        return response.data;
    });
}
exports.status = status;
function result(serverUrl, requestURI, authToken) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = utils_1.buildUrl(`${serverUrl}${requestURI}`);
        const response = yield utils_2.getRequest(url, authToken);
        return response.data;
    });
}
exports.result = result;
function submit(serverUrl, executionUuid, authToken, routeUuid, payload) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = utils_1.buildUrl(ENDPOINTS_URL.SUBMIT, {
            SERVER_URL: serverUrl,
            EXECUTION_UUID: executionUuid,
        });
        const response = yield utils_2.postRequest(url, authToken, {
            uuid: routeUuid,
            payload: payload,
        });
        return response.data;
    });
}
exports.submit = submit;
function back(serverUrl, executionUuid, authToken, routeUuid, payload) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = utils_1.buildUrl(ENDPOINTS_URL.BACK, {
            SERVER_URL: serverUrl,
            EXECUTION_UUID: executionUuid,
        });
        const response = yield utils_2.postRequest(url, authToken, {
            uuid: routeUuid,
            payload: payload,
        });
        return response.data;
    });
}
exports.back = back;
function execute(serverUrl, executionUuid, authToken, routeUuid, functionName, payload) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = utils_1.buildUrl(ENDPOINTS_URL.FUNCTION, {
            SERVER_URL: serverUrl,
            EXECUTION_UUID: executionUuid,
        });
        const response = yield utils_2.postRequest(url, authToken, {
            uuid: routeUuid,
            name: functionName,
            payload: payload,
        });
        return response.data;
    });
}
exports.execute = execute;
function exportedNamespace(serverUrl, executionUuid, authToken, namespaceName) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = utils_1.buildUrl(ENDPOINTS_URL.EXPORTED_NAMESPACE, {
            SERVER_URL: serverUrl,
            EXECUTION_UUID: executionUuid,
            EXECUTION_NAMESPACE: namespaceName,
        });
        const response = yield utils_2.getRequest(url, authToken);
        return response.data;
    });
}
exports.exportedNamespace = exportedNamespace;
function getFile(serverUrl, authToken, fileId) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = utils_1.buildUrl(ENDPOINTS_URL.GET_FILE, {
            SERVER_URL: serverUrl,
            FILE_UUID: fileId,
        });
        const response = yield utils_2.getRequest(url, authToken, true);
        return response.data.blob();
    });
}
exports.getFile = getFile;
function sendFile(serverUrl, authToken, file, progressCallback) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = utils_1.buildUrl(ENDPOINTS_URL.SEND_FILE, { SERVER_URL: serverUrl });
        const formData = new FormData();
        formData.append('file', file);
        const response = yield utils_1.Request.requestWithProgress(url, utils_1.HTTP_METHOD.POST, authToken, formData, false, progressCallback);
        Endpoints_1.expectStatus(response, utils_1.HTTP_STATUS.CREATED);
        return response.data;
    });
}
exports.sendFile = sendFile;
