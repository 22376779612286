"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROCESS_TYPE_SET = exports.PROCESS_TYPE_PEOPLE_SET = exports.PROCESS_TYPE_CHANGE_PAGE = exports.HOTJAR_STATE_CHANGE = exports.TAGMANAGER_GLOBAL_SET = exports.TAGMANAGER_PEOPLE_SET = exports.TAGMANAGER_CHANGE_PAGE = exports.TAGMANAGER_AUTH = void 0;
exports.TAGMANAGER_AUTH = 'authorization';
exports.TAGMANAGER_CHANGE_PAGE = 'changePage';
exports.TAGMANAGER_PEOPLE_SET = 'personalSet';
exports.TAGMANAGER_GLOBAL_SET = 'globalSet';
exports.HOTJAR_STATE_CHANGE = 'stateChange';
exports.PROCESS_TYPE_CHANGE_PAGE = 'changePage';
exports.PROCESS_TYPE_PEOPLE_SET = 'peopleSet';
exports.PROCESS_TYPE_SET = 'set';
