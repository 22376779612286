"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.goToErrorPage = exports.handleBackButtonClick = exports.flowHandoffStart = exports.flowResolvePage = exports.flowSubmit = exports.flowSharableStart = exports.flowStart = exports.flowReload = exports.getFlowExportedNamespace = exports.flowFunction = exports.flowBack = exports.flowRefresh = exports.flowContinue = exports.flowChangePage = void 0;
const hub_client_resolvers_1 = require("@zenoo/hub-client-resolvers");
const hub_client_translations_1 = require("@zenoo/hub-client-translations");
const utils_1 = require("@zenoo/hub-client-utils/utils");
const cookie_1 = require("../../../constants/cookie");
const expressions_1 = require("../../../expressions");
const auth_1 = require("../../../utils/auth");
const actions_1 = require("../analytics/actions");
const actions_2 = require("../api/actions");
const actions_3 = require("../api/actions");
const constants_1 = require("../api/constants");
const actions_4 = require("../app/actions");
const actions_5 = require("../form/actions");
const C = __importStar(require("./constants"));
let timer;
let timeOfEditing = 0;
exports.flowChangePage = (pageName, direction = 1, params = {}, uuid = null, formData = {}) => (dispatch, getState) => {
    dispatch({
        type: C.FLOW_CHANGE_PAGE_START,
        pageName,
        direction,
        params,
        uuid,
    });
    dispatch(actions_1.dispatchAnalyticsCustomEvent('page', 'leave', { [hub_client_resolvers_1.AnalyticsMapperParams.timeOfPageEditing]: timeOfEditing }));
    clearInterval(timer);
    timeOfEditing = 0;
    timer = setInterval(() => timeOfEditing++, 1000);
    try {
        const oldPageName = getState().workflow.currentPage;
        dispatch(actions_1.analyticsChangePage(oldPageName, pageName));
        if (utils_1.isString(getState().workflow.currentPageUuid)) {
            dispatch(actions_5.formTagsReset());
            dispatch(actions_5.formDataReset());
            dispatch(actions_2.setState({ progress: {}, functionResults: {} }));
        }
        dispatch({
            type: C.FLOW_CHANGE_PAGE_SUCCESS,
            pageName,
            uuid,
            direction,
            params,
        });
        if (pageName !== C.INDEX_PAGE) {
            const { persistQuery = false, persistPathname = false } = getState().app.configuration.url || {};
            const { search, pathname } = new URL(window.location.href);
            const path = `${persistPathname ? pathname : '/'}${persistQuery ? search : ''}#${pageName}`;
            window.history.pushState({ back: true }, '', path);
        }
        dispatch(actions_4.appStorageReset());
        dispatch(actions_4.appPageClearAllTimeoutsIntervals());
        dispatch(exports.flowResolvePage());
        dispatch(actions_5.formDataSet(Object.assign(Object.assign({}, (getState().workflow.currentResolvedPage.defaults || {})), (formData || {}))));
        dispatch(actions_1.dispatchAnalyticsCustomEvent('page', 'enter'));
    }
    catch (e) {
        dispatch({
            type: C.FLOW_CHANGE_PAGE_ERROR,
            error: e.message,
        });
    }
    if (!getState().form.validationInitialized) {
        dispatch(actions_5.formValidationInit());
    }
    dispatch(actions_5.formValidationCompileSchema(getState().workflow.currentResolvedPage.schema));
    dispatch(actions_5.formDataValidate());
};
exports.flowContinue = () => (dispatch) => dispatch(doFlowOperation(() => dispatch(actions_3.apiStatus()), C.FLOW_CONTINUE_ACTIONS, 1, false));
exports.flowRefresh = () => (dispatch) => dispatch(doFlowOperation(() => dispatch(actions_2.apiRefresh()), C.FLOW_CONTINUE_ACTIONS, 1, false));
exports.flowBack = () => (dispatch, getState) => dispatch(doFlowOperation(() => dispatch(actions_3.apiBack()), C.FLOW_BACK_ACTIONS, getState().workflow.currentResolvedPage.fadeAnimationBack ? 0 : -1));
exports.flowFunction = (functionName, resultKey, payload) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    if (getState().app.waitingFor.some(tag => tag.includes(constants_1.WAITING_API))) {
        return;
    }
    dispatch({ type: C.FLOW_FUNCTION_START, functionName, resultKey });
    try {
        const result = yield dispatch(actions_3.apiFunction(functionName, resultKey, payload));
        dispatch({ type: C.FLOW_FUNCTION_SUCCESS, functionName, resultKey, result });
        return result;
    }
    catch (e) {
        dispatch({ type: C.FLOW_FUNCTION_ERROR, functionName, resultKey, error: e.message });
    }
    return;
});
exports.getFlowExportedNamespace = (namespaceName, resultKey) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    if (getState().app.waitingFor.some(tag => tag.includes(constants_1.WAITING_API))) {
        return;
    }
    dispatch({ type: C.GET_FLOW_EXPORTED_NAMESPACE_START, namespaceName, resultKey });
    try {
        const result = yield dispatch(actions_3.apiGetExportedNamespace(namespaceName, resultKey));
        dispatch({ type: C.GET_FLOW_EXPORTED_NAMESPACE_SUCCESS, namespaceName, resultKey, result });
        return result;
    }
    catch (e) {
        dispatch({ type: C.GET_FLOW_EXPORTED_NAMESPACE_ERROR, namespaceName, resultKey, error: e.message });
    }
    return;
});
exports.flowReload = () => () => {
    auth_1.removeAuthCookie();
    window.location.reload();
};
exports.flowStart = (params) => (dispatch, getState) => dispatch(doFlowOperation(() => dispatch(actions_3.apiInit(params || getState().form.data)), C.FLOW_START_ACTIONS, 1, false));
exports.flowSharableStart = (token, params) => (dispatch, getState) => dispatch(doFlowOperation(() => dispatch(actions_3.apiSharableInit(token, params || getState().form.data)), C.FLOW_SHARABLE_TOKEN_START_ACTIONS, 1));
exports.flowSubmit = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    let payload = getState().form.data;
    const formOutputModifier = (_b = (_a = getState().workflow) === null || _a === void 0 ? void 0 : _a.currentResolvedPage) === null || _b === void 0 ? void 0 : _b.formOutputModifier;
    if (formOutputModifier) {
        payload = yield formOutputModifier();
    }
    return dispatch(doFlowOperation(() => dispatch(actions_3.apiSubmit(payload)), C.FLOW_SUBMIT_ACTIONS, getState().workflow.currentResolvedPage.fadeAnimationSubmit ? 0 : 1));
});
exports.flowResolvePage = () => (dispatch) => {
    const expressionContext = dispatch(expressions_1.createPageExpressionContext());
    dispatch({ type: C.FLOW_RESOLVE_PAGE_START, expressionContext });
    try {
        const resolvedPage = dispatch(getResolvedPage(expressionContext));
        dispatch({ type: C.FLOW_RESOLVE_PAGE_SUCCESS, resolvedPage, expressionContext });
    }
    catch (e) {
        dispatch({ type: C.FLOW_RESOLVE_PAGE_ERROR, error: e.message });
    }
};
exports.flowHandoffStart = (executionUuid, authToken) => (dispatch) => {
    dispatch(actions_2.setState({
        executionUuid,
        authToken,
    }));
    dispatch(exports.flowContinue());
};
exports.handleBackButtonClick = () => (dispatch, getState) => {
    dispatch({ type: C.FLOW_BACK_BUTTON_CLICKED });
    if (getState().api.backEnabled) {
        dispatch(exports.flowBack());
    }
    else {
        alert(getState().app.configuration.backDisabledAlert
            ? getState().app.configuration.backDisabledAlert
            : hub_client_translations_1.translate('CANT_GO_BACK'));
        window.history.pushState({ back: true }, '', '/');
    }
};
exports.goToErrorPage = (errorMessage) => (dispatch, getState) => {
    const errorPage = getState().app.configuration.errorPage;
    if (errorPage && getState().app.configuration.pages.hasOwnProperty(errorPage)) {
        dispatch(exports.flowChangePage(errorPage, 0, { error: errorMessage }));
    }
    else {
        if (!utils_1.ifCookiesIsTrue([cookie_1.COOKIE_DEVELOPMENT, cookie_1.COOKIE_DISABLE_RELOAD])) {
            console.warn(hub_client_translations_1.translate('ERROR_PAGE_NOT_SPECIFIED'), errorMessage);
            setTimeout(() => window.location.reload(), 5000);
        }
    }
};
const doFlowOperation = (action, flowOperation, direction = 1, showErrorPage = true) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    if (getState().app.waitingFor.some(tag => tag.includes(constants_1.WAITING_API))) {
        return;
    }
    dispatch({ type: flowOperation.start });
    try {
        const result = yield action();
        if (result) {
            const currentPageUuid = getState().workflow.currentPageUuid;
            if (currentPageUuid !== result.uuid) {
                const newPageName = result.uri.substr(1);
                dispatch(exports.flowChangePage(newPageName, direction, {}, result.uuid, result.payload));
            }
            else {
                dispatch(actions_5.formDataSet(result.payload));
            }
            if (result.terminal) {
                dispatch(actions_4.appLogout());
            }
        }
        if (!getState().workflow.authCookieInitialized) {
            dispatch(initAuthCookie());
        }
        dispatch({ type: flowOperation.success });
        return true;
    }
    catch (error) {
        if (flowOperation.validationError && error.response && error.response.data && error.response.data.errors) {
            dispatch({ type: flowOperation.validationError, errors: error.response.data.errors });
        }
        else {
            dispatch({ type: flowOperation.error, error: error.code || error.message });
            if (showErrorPage) {
                dispatch(exports.goToErrorPage(error.code || error.message));
            }
        }
        return false;
    }
});
const getResolvedPage = (context = null, pageName = null) => (dispatch, getState) => {
    const configuration = getState().app.configuration;
    const currentPage = pageName || getState().workflow.currentPage;
    const expressionContext = context || dispatch(expressions_1.createPageExpressionContext());
    return hub_client_resolvers_1.PageResolver.resolvePage(configuration, currentPage, expressionContext);
};
const initAuthCookie = () => (dispatch, getState) => {
    const { executionUuid, authToken } = getState().api;
    const { authorizationTimeout } = getState().app.configuration;
    auth_1.setAuthCookie(executionUuid, authToken, authorizationTimeout);
    auth_1.startRefreshAuthCookieExpiration(authorizationTimeout);
    dispatch({ type: C.FLOW_AUTH_COOKIE_INITIALIZED, executionUuid, authToken, authorizationTimeout });
};
