"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.saveState = exports.processApiGet = exports.processApiPost = exports.apiGetWithSave = exports.apiPostWithResultSave = exports.apiPost = exports.apiPostWithResult = exports.retryApiCall = exports.apiCall = exports.handleErrorFunction = exports.processFiles = void 0;
const types_1 = require("@zenoo/hub-client-core/types");
const utils_1 = require("@zenoo/hub-client-utils/utils");
const actions_1 = require("../../../../api/v1/actions");
const auth_1 = require("../../../../utils/auth");
const Errors_1 = require("../../../../utils/Errors");
const promise_1 = require("../../../../utils/promise");
const actions_2 = require("../../workflow/actions");
const actions_3 = require("../actions");
const types_2 = require("../types");
const endpoints = __importStar(require("./actions"));
const EXECUTION_NOT_FOUND_CODE = 'execution-not-found';
exports.processFiles = (payload) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        const processedPayload = {};
        yield Promise.all(Object.keys(payload || {}).map((key) => __awaiter(void 0, void 0, void 0, function* () {
            if (payload[key] instanceof utils_1.FileHolder) {
                const response = yield dispatch(endpoints.sendFile(payload[key], progress => dispatch(actions_3.setProgress(key, progress))));
                processedPayload[key] = response;
            }
            else if (utils_1.isArrayInstancesOf(payload[key], utils_1.FileHolder)) {
                const response = yield dispatch(endpoints.sendFiles(payload[key], key));
                processedPayload[key] = response;
            }
            else {
                processedPayload[key] = payload[key];
            }
        })));
        return processedPayload;
    });
};
exports.handleErrorFunction = (apiCallAction) => (dispatch) => (error) => {
    if (error instanceof Errors_1.UnexpectedStatusError) {
        if (error.response.status === utils_1.HTTP_STATUS.UNPROCESSABLE_ENTITY) {
            dispatch(actions_3.callStoreAction(apiCallAction.validationError, error.message));
        }
        else if (error.response.status === utils_1.HTTP_STATUS.UNAUTHORIZED) {
            dispatch(actions_3.callStoreAction(apiCallAction.error, error.message));
        }
    }
    return null;
};
function apiCall(tags, promise, silently) {
    return (dispatch) => {
        if (silently) {
            return promise;
        }
        return promise_1.appPromise(dispatch, tags, promise);
    };
}
exports.apiCall = apiCall;
function retryApiCall(tags, promise, backupFunction = promise_1.decision.baseNotValidationCatch) {
    return (dispatch) => {
        return promise_1.appRetryPromise(dispatch, tags, promise, backupFunction);
    };
}
exports.retryApiCall = retryApiCall;
const apiPostWithAuth = (apiCallAction, promise, backupFunction = null) => {
    return (dispatch) => {
        dispatch(actions_3.callStoreAction(apiCallAction.start));
        return dispatch(apiCall(apiCallAction.tags, (() => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield promise_1.retryPromise(promise, backupFunction || dispatch(exports.handleErrorFunction(apiCallAction)));
            return dispatch(exports.processApiPost(response));
        }))()));
    };
};
exports.apiPostWithResult = (apiCallAction, promise, backupFunction = null, ignoreExecutionError) => {
    return (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
        return dispatch(apiGet(apiCallAction, () => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield dispatch(apiPostWithAuth(apiCallAction, promise, backupFunction || dispatch(exports.handleErrorFunction(apiCallAction))));
            return actions_1.result(getState().api.serverUrl, response.requestURI, getState().api.authToken);
        }), backupFunction || dispatch(exports.handleErrorFunction(apiCallAction)), false, ignoreExecutionError));
    });
};
exports.apiPost = (apiCallAction, promise, backupFunction = null) => {
    return (dispatch) => {
        dispatch(actions_3.callStoreAction(apiCallAction.start));
        return dispatch(apiCall(apiCallAction.tags, promise_1.retryPromise(promise, backupFunction || dispatch(exports.handleErrorFunction(apiCallAction)))));
    };
};
exports.apiPostWithResultSave = (apiCallAction, promise, backupFunction = null) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield dispatch(exports.apiPostWithResult(apiCallAction, promise, backupFunction || dispatch(exports.handleErrorFunction(apiCallAction))));
    return dispatch(exports.saveState(response, apiCallAction));
});
const apiGet = (apiCallAction, promise, backupFunction = null, silently, ignoreExecutionError) => (dispatch) => dispatch(apiCall(apiCallAction.tags, (() => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield promise_1.retryPromise(promise, backupFunction || dispatch(exports.handleErrorFunction(apiCallAction)));
    return dispatch(exports.processApiGet(response, apiCallAction, ignoreExecutionError));
}))(), silently));
exports.apiGetWithSave = (apiCallAction, promise, backupFunction = null, silently) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(actions_3.callStoreAction(apiCallAction.start));
    const response = yield dispatch(apiGet(apiCallAction, promise, backupFunction || dispatch(exports.handleErrorFunction(apiCallAction)), silently));
    return dispatch(exports.saveState(response, apiCallAction));
});
const parseExecutionUuid = (executionURI) => /[^\/]+$/g.exec(executionURI)[0];
exports.processApiPost = (postResponse) => (dispatch, getState) => {
    const executionUuid = parseExecutionUuid(postResponse.executionURI);
    dispatch(actions_3.setState({
        executionUuid: executionUuid,
        authToken: postResponse.token,
    }));
    auth_1.setAuthCookie(executionUuid, postResponse.token, getState().app.configuration.authorizationTimeout);
    return postResponse;
};
exports.processApiGet = (response, apiCallAction, ignoreExecutionError) => (dispatch) => {
    if (!response.type || response.type === types_1.ApiResponseType.ROUTE) {
        return response;
    }
    else if (response.type === types_1.ApiResponseType.RESULT) {
        return response.result;
    }
    else if (response.type === types_1.ApiResponseType.VALIDATION_ERROR) {
        dispatch(actions_3.setValidationErrors(response.errors));
        dispatch(actions_3.callStoreAction(apiCallAction.validationError, response.errors));
    }
    else if (response.type === types_1.ApiResponseType.EXECUTION_ERROR && response.code === EXECUTION_NOT_FOUND_CODE) {
        if (!ignoreExecutionError) {
            dispatch(actions_2.flowReload());
        }
    }
    else {
        dispatch(actions_3.callStoreAction(apiCallAction.error, response.message));
        throw new types_2.ApiResponseError(response.message);
    }
};
exports.saveState = (response, apiCallAction) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(actions_3.callStoreAction(apiCallAction.done));
        dispatch(actions_3.setState({
            status: response,
            export: response.export,
            payload: response.payload,
            backEnabled: response.backEnabled,
        }));
        if (response.payload) {
            response.payload = yield utils_1.translateFiles(response.payload, (object) => __awaiter(void 0, void 0, void 0, function* () {
                const blob = yield dispatch(endpoints.getFile(object.uuid));
                return utils_1.FileHolder.getHolder(blob, object);
            }));
            return response;
        }
        return response;
    });
};
