"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEND_FILE = exports.GET_FILE = exports.LEGACY_BASE_URL = exports.RESULT = exports.STATUS = exports.EXPORTED_NAMESPACE = exports.FUNCTION = exports.BACK = exports.SUBMIT = exports.SHARABLE_INIT = exports.INIT = exports.BASE_URL = void 0;
const SERVER_URL = '{SERVER_URL}';
const EXECUTION_UUID = '{EXECUTION_UUID}';
const REQUEST_UUID = '{REQUEST_UUID}';
const FILE_UUID = '{FILE_UUID}';
const TOKEN = '{TOKEN}';
const GATEWAY = 'gateway';
const API = 'api';
const EXECUTION_NAMESPACE = '{EXECUTION_NAMESPACE}';
exports.BASE_URL = `${SERVER_URL}/${API}/${GATEWAY}`;
exports.INIT = `${exports.BASE_URL}/execution`;
exports.SHARABLE_INIT = `${exports.BASE_URL}/sharable/${TOKEN}`;
exports.SUBMIT = `${exports.BASE_URL}/execution/${EXECUTION_UUID}/submit`;
exports.BACK = `${exports.BASE_URL}/execution/${EXECUTION_UUID}/back`;
exports.FUNCTION = `${exports.BASE_URL}/execution/${EXECUTION_UUID}/function`;
exports.EXPORTED_NAMESPACE = `${exports.BASE_URL}/execution/${EXECUTION_UUID}/export/${EXECUTION_NAMESPACE}`;
exports.STATUS = `${exports.BASE_URL}/execution/${EXECUTION_UUID}`;
exports.RESULT = `${exports.BASE_URL}/request/${REQUEST_UUID}`;
exports.LEGACY_BASE_URL = `${SERVER_URL}/${API}`;
exports.GET_FILE = `${exports.LEGACY_BASE_URL}/files/${FILE_UUID}`;
exports.SEND_FILE = `${exports.LEGACY_BASE_URL}/files/cache`;
