"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const hub_client_components_1 = require("@zenoo/hub-client-components");
const hub_client_core_1 = require("@zenoo/hub-client-core");
const redux_1 = require("redux");
exports.default = {
    Accordion: hub_client_components_1.Accordion,
    AnchorButton: hub_client_components_1.AnchorButton,
    Button: hub_client_components_1.Button,
    CircularProgress: hub_client_components_1.CircularProgress,
    ContentBox: hub_client_components_1.ContentBox,
    CopyToClipboard: hub_client_components_1.CopyToClipboard,
    DelayedRepeatButton: hub_client_components_1.DelayedRepeatButton,
    Header: hub_client_components_1.Header,
    Icon: hub_client_components_1.Icon,
    InfoBox: hub_client_components_1.InfoBox,
    InputGroup: hub_client_components_1.InputGroup,
    Loader: hub_client_components_1.Loader,
    Map: hub_client_components_1.Map,
    Modal: hub_client_core_1.connectors.connectModal(hub_client_components_1.Modal),
    Progress: hub_client_components_1.Progress,
    QRCode: hub_client_components_1.QRCode,
    SidebarProgress: hub_client_components_1.SidebarProgress,
    SVG: hub_client_components_1.SVG,
    Table: hub_client_components_1.Table,
    Heading: hub_client_components_1.Heading,
    Markdown: hub_client_components_1.Markdown,
    Paragraph: hub_client_components_1.Paragraph,
    SimpleButton: redux_1.compose(hub_client_core_1.connectors.connectSubmit, hub_client_core_1.connectors.connectLoading({ tags: 'WAITING_API' }))(hub_client_components_1.SimpleButton),
    SubmitButton: redux_1.compose(hub_client_core_1.connectors.connectSubmit, hub_client_core_1.connectors.connectLoading({ tags: 'WAITING_API' }))(hub_client_components_1.Button),
    SetButton: hub_client_core_1.connectors.connectFormSet(hub_client_components_1.Button),
    TagButton: hub_client_core_1.connectors.connectFormTag(hub_client_components_1.Button),
    LabelButton: hub_client_core_1.connectors.connectSubmit(hub_client_components_1.LabelButton),
    LinkButton: hub_client_core_1.connectors.connectSubmit(hub_client_components_1.LinkButton),
    DelayedRender: hub_client_components_1.DelayedRender,
    Fragment: hub_client_components_1.Fragment,
    Interval: hub_client_components_1.Interval,
    Portal: hub_client_components_1.Portal,
    ResendCode: hub_client_core_1.connectors.connectWorkflowFunction({ functionName: 'OTPResend', resultKey: 'resendData' })(hub_client_components_1.ResendCode),
    Script: hub_client_components_1.Script,
    Trigger: hub_client_components_1.Trigger,
    VisibilityWrapper: hub_client_components_1.VisibilityWrapper,
    AcuantFileUpload: hub_client_core_1.connectors.connectFileUpload(hub_client_components_1.AcuantFileUpload),
    AcuantFileUploadButton: hub_client_core_1.connectors.connectFileUpload(hub_client_components_1.AcuantFileUploadButton),
    AddressInput: hub_client_core_1.connectors.connectField(hub_client_components_1.AddressInput),
    Checkbox: hub_client_core_1.connectors.connectField(hub_client_components_1.Checkbox),
    CreditCardInput: hub_client_core_1.connectors.connectField(hub_client_components_1.CreditCardInput),
    Datepicker: hub_client_core_1.connectors.connectField(hub_client_components_1.Datepicker),
    FileUpload: hub_client_core_1.connectors.connectFileUpload(hub_client_components_1.FileUpload),
    GlobalError: hub_client_core_1.connectors.connectGlobalError(hub_client_components_1.GlobalError),
    IdemiaFileUpload: hub_client_core_1.connectors.connectFileUpload(hub_client_components_1.IdemiaFileUpload),
    Image: hub_client_core_1.connectors.connectImage(hub_client_components_1.Image),
    SVGImage: hub_client_core_1.connectors.connectImage(hub_client_components_1.SVGImage),
    Input: hub_client_core_1.connectors.connectField(hub_client_components_1.Input),
    Iovation: hub_client_core_1.connectors.connectField(hub_client_components_1.Iovation),
    MaskedInput: hub_client_core_1.connectors.connectField(hub_client_components_1.MaskedInput),
    PhoneInput: hub_client_core_1.connectors.connectField(hub_client_components_1.PhoneInput),
    PinInput: hub_client_core_1.connectors.connectField(hub_client_components_1.PinInput),
    Radio: hub_client_core_1.connectors.connectField(hub_client_components_1.Radio),
    RadioButton: hub_client_core_1.connectors.connectField(hub_client_components_1.RadioButton),
    Rating: hub_client_core_1.connectors.connectField(hub_client_components_1.Rating),
    Select: hub_client_core_1.connectors.connectField(hub_client_components_1.Select),
    Signature: hub_client_core_1.connectors.connectFileUpload(hub_client_components_1.Signature),
    Slider: hub_client_core_1.connectors.connectField(hub_client_components_1.Slider),
    SmsInput: hub_client_core_1.connectors.connectField(hub_client_components_1.SmsInput),
    Textarea: hub_client_core_1.connectors.connectField(hub_client_components_1.Textarea),
    Toggle: hub_client_core_1.connectors.connectField(hub_client_components_1.Toggle),
    LayoutSingleColumn: hub_client_components_1.LayoutSingleColumn,
    LayoutWithSidebar: hub_client_components_1.LayoutWithSidebar,
};
