"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveAnalyticsParams = exports.dispatchAnalyticsCustomEvent = exports.dispatchAnalyticsEvent = exports.analyticsChangePage = exports.analyticsAuthorization = exports.analyticsInit = void 0;
const hub_client_resolvers_1 = require("@zenoo/hub-client-resolvers");
const hub_client_translations_1 = require("@zenoo/hub-client-translations");
const utils_1 = require("@zenoo/hub-client-utils/utils");
const analytics_1 = require("../../../constants/analytics");
const expressions_1 = require("../../../expressions");
const C = __importStar(require("./constants"));
const providers_1 = require("./providers");
exports.analyticsInit = (analyticsConfig) => (dispatch) => {
    const configuredProviders = Object.keys(analyticsConfig);
    if (!configuredProviders.length) {
        return;
    }
    dispatch({ type: C.ANALYTICS_INIT_START, providers: configuredProviders });
    const initializedProviders = [];
    const errors = {};
    configuredProviders.forEach(configKey => {
        if (Object.keys(providers_1.providers).some(providerKey => configKey === providerKey)) {
            try {
                providers_1.providers[configKey].init(analyticsConfig[configKey]);
                initializedProviders.push(configKey);
            }
            catch (e) {
                errors[configKey] = e.message;
            }
        }
    });
    if (initializedProviders.length) {
        dispatch({ type: C.ANALYTICS_INIT_SUCCESS, providers: initializedProviders });
    }
    if (Object.keys(errors).length) {
        dispatch({ type: C.ANALYTICS_INIT_ERROR, providers: Object.keys(errors), errors });
    }
    if (!initializedProviders.length && !Object.keys(errors).length) {
        dispatch({
            type: C.ANALYTICS_INIT_ERROR,
            providers: configuredProviders,
            errors: configuredProviders.reduce((acc, providerKey) => (Object.assign(Object.assign({}, acc), { [providerKey]: hub_client_translations_1.translate('ANALYTICS_PROVIDER_NOT_FOUND', {
                    provider: providerKey,
                    availableProviders: providers_1.providers.toString(),
                }) })), {}),
        });
    }
};
exports.analyticsAuthorization = (token) => (dispatch, getState) => {
    if (!token) {
        return;
    }
    const initializedProviders = getState().analytics.initializedProviders;
    if (initializedProviders === null || initializedProviders === void 0 ? void 0 : initializedProviders.length) {
        dispatch({
            type: C.ANALYTICS_AUTHORIZATION_START,
            providers: initializedProviders,
        });
        const successProviders = [];
        const errors = {};
        initializedProviders.forEach(providerKey => {
            try {
                providers_1.providers[providerKey].authorization(token);
                successProviders.push(providerKey);
            }
            catch (e) {
                errors[providerKey] = e.message;
            }
        });
        if (successProviders.length) {
            dispatch({
                type: C.ANALYTICS_AUTHORIZATION_SUCCESS,
                providers: successProviders,
            });
        }
        if (Object.keys(errors).length) {
            dispatch({
                type: C.ANALYTICS_AUTHORIZATION_ERROR,
                providers: Object.keys(errors),
                errors,
            });
        }
    }
};
exports.analyticsChangePage = (oldPageName, newPageName) => (dispatch, getState) => {
    const initializedProviders = getState().analytics.initializedProviders;
    if (initializedProviders === null || initializedProviders === void 0 ? void 0 : initializedProviders.length) {
        const successProviders = [];
        const errors = {};
        dispatch({
            type: C.ANALYTICS_CHANGE_PAGE_START,
            providers: initializedProviders,
            oldPageName,
            newPageName,
        });
        const analyticsPageName = dispatch(getAnalyticsPageName(newPageName));
        const peopleSetProcessedValues = dispatch(getAnalyticsProcessedValues(oldPageName, newPageName, analytics_1.PROCESS_TYPE_PEOPLE_SET));
        const setParamsProcessedValues = dispatch(getAnalyticsProcessedValues(oldPageName, newPageName, analytics_1.PROCESS_TYPE_SET));
        let changePageProcessedValues = dispatch(getAnalyticsProcessedValues(oldPageName, newPageName, analytics_1.PROCESS_TYPE_CHANGE_PAGE));
        const globalParams = dispatch(exports.resolveAnalyticsParams());
        initializedProviders.forEach(providerKey => {
            try {
                if (Object.keys(peopleSetProcessedValues).length) {
                    providers_1.providers[providerKey].peopleSet(peopleSetProcessedValues);
                }
                if (Object.keys(setParamsProcessedValues).length) {
                    providers_1.providers[providerKey].setParams(setParamsProcessedValues);
                }
                if (globalParams && utils_1.isObject(globalParams)) {
                    changePageProcessedValues = Object.assign(Object.assign({}, changePageProcessedValues), globalParams);
                }
                providers_1.providers[providerKey].changePage(analyticsPageName, changePageProcessedValues);
                successProviders.push(providerKey);
            }
            catch (e) {
                errors[providerKey] = e.message;
            }
        });
        if (successProviders.length) {
            dispatch({
                type: C.ANALYTICS_CHANGE_PAGE_SUCCESS,
                providers: successProviders,
                newPageName,
            });
        }
        if (Object.keys(errors).length) {
            dispatch({
                type: C.ANALYTICS_CHANGE_PAGE_ERROR,
                providers: Object.keys(errors),
                errors,
            });
        }
    }
};
exports.dispatchAnalyticsEvent = (name, params) => (dispatch, getState) => {
    if (!utils_1.isString(name)) {
        throw new utils_1.CoreError('ANALYTICS_FIRST_MUST_BE_STRING');
    }
    if (params && !utils_1.isObject(params)) {
        throw new utils_1.CoreError('ANALYTICS_SECOND_MUST_BE_OBJECT');
    }
    if (utils_1.isObject(params) || utils_1.isUndefined(params)) {
        const globalParams = dispatch(exports.resolveAnalyticsParams());
        if (globalParams && utils_1.isObject(globalParams)) {
            params = Object.assign(Object.assign({}, globalParams), params);
        }
    }
    const initializedProviders = getState().analytics.initializedProviders;
    if (initializedProviders && initializedProviders.length) {
        dispatch({
            type: C.ANALYTICS_CUSTOM_EVENT_START,
            providers: initializedProviders,
            eventName: name,
        });
        const successProviders = [];
        const errors = {};
        initializedProviders.forEach(providerKey => {
            try {
                providers_1.providers[providerKey].customEvent(name, params);
                successProviders.push(providerKey);
            }
            catch (e) {
                errors[providerKey] = e.message;
            }
        });
        if (successProviders.length) {
            dispatch({
                type: C.ANALYTICS_CUSTOM_EVENT_SUCCESS,
                providers: successProviders,
                eventName: name,
            });
        }
        if (Object.keys(errors).length) {
            dispatch({
                type: C.ANALYTICS_CUSTOM_EVENT_ERROR,
                providers: Object.keys(errors),
                errors,
                eventName: name,
            });
        }
    }
};
exports.dispatchAnalyticsCustomEvent = (key, name, params = {}) => (dispatch, getState) => {
    var _a;
    const { currentPage, currentPageContext } = getState().workflow;
    const { configuration } = getState().app;
    const events = ((_a = configuration.pages[currentPage]) === null || _a === void 0 ? void 0 : _a.analytics) || {};
    const paramsMapper = configuration.analyticsMapper;
    const mappedParams = paramsMapper
        ? Object.keys(params).reduce((acc, param) => (Object.assign(Object.assign({}, acc), { [paramsMapper[param] || param]: params[param] })), {})
        : params;
    const parts = key.split('.');
    for (let i = 0; i < parts.length; i++) {
        const def = utils_1.getByExpression(events, parts.slice(0, i + 1).join('.'), null);
        if (def === null || def === void 0 ? void 0 : def[name]) {
            const result = hub_client_resolvers_1.ExpressionResolver.resolveExpressions(currentPageContext, def[name], {});
            if (utils_1.isFunction(result)) {
                result(mappedParams);
            }
            else if (utils_1.isString(result)) {
                dispatch(exports.dispatchAnalyticsEvent(result, mappedParams));
            }
            else if (utils_1.isObject(result)) {
                const { eventName, data = {} } = result;
                dispatch(exports.dispatchAnalyticsEvent(eventName, Object.assign(Object.assign({}, mappedParams), data)));
            }
        }
    }
};
const getAnalyticsPageName = (pageName) => (dispatch, getState) => {
    return hub_client_resolvers_1.AnalyticsResolver.resolveAnalyticsPageName(getState().app.configuration, pageName, dispatch(expressions_1.createAnalyticsExpressionContext()));
};
const getAnalyticsProcessedValues = (oldPageName, newPageName, type) => (dispatch, getState) => {
    return hub_client_resolvers_1.AnalyticsResolver.resolveAnalyticsValues(getState().app.configuration, oldPageName, newPageName, type, dispatch(expressions_1.createAnalyticsExpressionContext()));
};
exports.resolveAnalyticsParams = () => (dispatch, getState) => {
    const { currentPageContext } = getState().workflow;
    const { configuration } = getState().app;
    const params = hub_client_resolvers_1.AnalyticsResolver.resolveAnalyticsParams(configuration, currentPageContext);
    dispatch({
        type: C.ANALYTICS_RESOLVE_PARAMS,
        params,
    });
    return params;
};
